/*
* COLORS
*/
$purple: #CD66FF;
$grey-light: #F6F6F6;
$grey: #9C9C9C;
$grey-alt: #949494;
$white: #ffffff;

/**
 * COLORS BY USAGE
 */
$primaryActionColor: $purple;

$formBackground: $grey-light;

$linkForeground: $primaryActionColor;
$linkSecondary: $grey;

$selectionRectBorder: $purple;
$selectionRectBackground: rgba($selectionRectBorder, .3);

/*
* FONTS
*/
$main-font: 'Fira Sans', sans-serif;
$alt-font: 'Roboto', sans-serif;