@import 'mixins';
@import 'fonts';
@import 'variables';
@import 'reset';


*, body{
  font-family: $main-font;
  font-size: 16px;
  overscroll-behavior: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

::-webkit-scrollbar {
  display: none;
}