@import "../variables";

a{
  color: $linkForeground;
  text-decoration: none;

  &:hover{
    text-decoration: underline;
  }
}

.Link{
  @extend a;
}